import { createSelector } from '@reduxjs/toolkit';

import { HeaderState } from '@definitions/header.types';

import { GlobalState } from '../createStore';

export const selectHeaderDomain = (state: GlobalState) => state.header;

export const selectHeaderGroupings = createSelector(selectHeaderDomain, (state: HeaderState) => state.groupings);

export const selectHeaderDropdown = createSelector(selectHeaderDomain, (state: HeaderState) => state.dropdown);

export const selectArtistHeaderDropdown = createSelector(selectHeaderDomain, (state: HeaderState) => state.artistDropdown);

export const selectObjectsHeaderDropdown = createSelector( selectHeaderDomain, (state: HeaderState) => state.objectsDropdown);

export const selectHeaderGrouping = createSelector(selectHeaderDomain, (state: HeaderState) => state.grouping);
