import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import api from '@services/api';
import { FetchHeaderGroupingPayload } from '@definitions/header.types';
import { Artwork } from '@definitions/artworks.types';
import { STORE_FRONT_KEY } from '@modules/plp';

const PREFIX = 'HEADER';

const query = {
  storefrontKey: STORE_FRONT_KEY,
};

export const fetchHeaderGroupings = createAsyncThunk(`${PREFIX}/FETCH_HEADER_GROUPINGS`, async () => {
  const {
    data: { groupings },
  } = await api.get('/artworks/groupings/', {
    params: query,
  });
  return groupings;
});

export const fetchHeaderDropdown = createAsyncThunk(`${PREFIX}/FETCH_HEADER_DROPDOWN`, async () => {
  const {
    data: { dropdown },
  } = await api.get('/artworks/dropdown/', {
    params: query,
  });
  return dropdown;
});

export const fetchArtistHeaderDropdown = createAsyncThunk(`${PREFIX}/FETCH_ARTIST_HEADER_DROPDOWN`, async () => {
  const {
    data: { dropdown },
  } = await api.get('/artists_dropdown/', {
    params: query,
  });
  return dropdown;
});

export const fetchObjetsHeaderDropdown = createAsyncThunk(`${PREFIX}/FETCH_OBJECTS_HEADER_DROPDOWN`, async () => {
  const {
    data: { dropdown },
  } = await api.get('/artworks/objects_dropdown/', {
    params: query,
  });
  return dropdown;
});

export const fetchHeaderGrouping = createAsyncThunk<Artwork[], FetchHeaderGroupingPayload>(
  `${PREFIX}/FETCH_HEADER_GROUPING`,
  async ({ slug }, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/artworks/groupings/${slug}/`, {
        params: query,
      });
      return data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        return rejectWithValue(e.response);
      }
    }
  }
);
