import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { Grouping, Dropdown, HeaderState } from '@definitions/header.types';
import { Artwork } from '@definitions/artworks.types';

import * as actions from './header.actions';

export const initialState: HeaderState = {
  groupings: null,
  grouping: null,
  dropdown: null,
  artistDropdown: null,
  objectsDropdown : null,
};

const handleFetchHeaderGroupings = (state: HeaderState, { payload }: PayloadAction<Grouping[]>) => {
  state.groupings = payload;
};

const handleFetchHeaderDropdown = (state: HeaderState, { payload }: PayloadAction<Dropdown[]>) => {
  state.dropdown = payload;
};

const handleFetchArtistHeaderDropdown = (state: HeaderState, { payload }: PayloadAction<Dropdown[]>) => {
  state.artistDropdown = payload;
};

const handleFetchObjectHeaderDropdown = (state: HeaderState, { payload }: PayloadAction<Dropdown[]>) => {
  state.objectsDropdown = payload;
};

const handleFetchHeaderGrouping = (state: HeaderState, { payload }: PayloadAction<Artwork[]>) => {
  state.grouping = payload;
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(actions.fetchHeaderGroupings.fulfilled, handleFetchHeaderGroupings);
  builder.addCase(actions.fetchHeaderGrouping.fulfilled, handleFetchHeaderGrouping);
  builder.addCase(actions.fetchHeaderDropdown.fulfilled, handleFetchHeaderDropdown);
  builder.addCase(actions.fetchArtistHeaderDropdown.fulfilled, handleFetchArtistHeaderDropdown);
  builder.addCase(actions.fetchObjetsHeaderDropdown.fulfilled, handleFetchObjectHeaderDropdown);
});
